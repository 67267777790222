import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Summary from "./Summary";
import Table from "./Table";
import { loading as stateLoading } from "redux/app";
import { selectedMonth, selectedUnit } from "redux/app/reducers/tabsSlice";
import PageLoader from "Components/Shared/PageSpinner";

const MonthlyStatement = () => {
  const monthSelected = useSelector(selectedMonth);
  const globalLoading = useSelector(stateLoading);
  const baseId = useSelector(selectedUnit);
  const containerRef = useRef(null);

  const [tableLoading, setTableLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      {/* Global loader for the whole page */}
      {globalLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 100,
          }}
        >
          <PageLoader />
        </div>
      )}

      {/* Main content area */}
      <Header />
      <Summary month={monthSelected} />

      {/* Table area with its own loader */}
      <div style={{ position: "relative" }}>
        {/* {tableLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 50,
            }}
          >
            <PageLoader />
          </div>
        )} */}
        <Table month={monthSelected} />
      </div>
    </div>
  );
};

export default MonthlyStatement;
